import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

// Define the validation schema using Yup
const ContactSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  mobile: Yup.string()
    .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, 'Invalid mobile number')
    .required('Required'),
  message: Yup.string(), // Removed validation constraints
});

const ContactForm = () => {
  const [responseMessage, setResponseMessage] = useState(''); // State to hold the response message

  // Define the endpoint directly
  const endpointUrl = 'https://demo.madarth.com/madarth/contact-email.php';

  return (
    <div>
      <Formik
        initialValues={{ name: '', email: '', mobile: '', message: '' }}
        validationSchema={ContactSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            const response = await fetch(endpointUrl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(values), // Send all form values directly
            });

            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json(); // Assuming the PHP script returns JSON

            if (data.success) {
              setResponseMessage('Message sent successfully!');
              resetForm();
            } else {
              setResponseMessage(data.message || 'Failed to send message. Please try again later.');
            }
          } catch (error) {
            console.error('Failed to submit form:', error);
            setResponseMessage('Failed to send message. Please try again later.');
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <div className='form__group interested__service--lists'>
              <label className="form_label__title">Let’s begin today</label>
            </div>

            {/* Name Field */}
            <div className='form__group'>
              <label className="form_label" htmlFor="name">Name:</label>
              <Field
                name="name"
                type="text"
                placeholder="Name"
                className={errors.name && touched.name ? "error-required" : "form__input"}
              />
              {errors.name && touched.name && (
                <div className='error error__message'>{errors.name}</div>
              )}
            </div>

            {/* Email Field */}
            <div className='form__group'>
              <label className="form_label" htmlFor="email">Email:</label>
              <Field
                name="email"
                type="email"
                placeholder="Email"
                className={errors.email && touched.email ? "error-required" : "form__input"}
              />
              {errors.email && touched.email && (
                <div className='error error__message'>{errors.email}</div>
              )}
            </div>

            {/* Mobile Field */}
            <div className='form__group'>
              <label className="form_label" htmlFor="mobile">Mobile:</label>
              <Field
                name="mobile"
                type="text"
                placeholder="Mobile"
                className={errors.mobile && touched.mobile ? "error-required" : "form__input"}
              />
              {errors.mobile && touched.mobile && (
                <div className='error error__message'>{errors.mobile}</div>
              )}
            </div>

            {/* Message Field */}
            <div className='form__group'>
              <label className="form_label" htmlFor="message">Message:</label>
              <Field
                as="textarea"
                name="message"
                placeholder="Message"
                className="form__input" // No conditional error class
              />
              {/* Removed error message display for message */}
            </div>

            {/* Submit Button */}
            <div className='form__group'>
              <button type="submit" className='form__button' disabled={isSubmitting}>
                <span className='button__text'>SEND MESSAGE</span>
                {/* SVG Icon */}
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2 12C2 11.8011 2.07902 11.6103 2.21967 11.4696C2.36032 11.329 2.55109 11.25 2.75 11.25H20.4395L15.719 6.53097C15.5782 6.39014 15.4991 6.19913 15.4991 5.99997C15.4991 5.80081 15.5782 5.6098 15.719 5.46897C15.8598 5.32814 16.0508 5.24902 16.25 5.24902C16.4492 5.24902 16.6402 5.32814 16.781 5.46897L22.781 11.469C22.8508 11.5386 22.9063 11.6214 22.9441 11.7125C22.9819 11.8036 23.0013 11.9013 23.0013 12C23.0013 12.0986 22.9819 12.1963 22.9441 12.2874C22.9063 12.3785 22.8508 12.4613 22.781 12.531L16.781 18.531C16.6402 18.6718 16.4492 18.7509 16.25 18.7509C16.0508 18.7509 15.8598 18.6718 15.719 18.531C15.5782 18.3901 15.4991 18.1991 15.4991 18C15.4991 17.8008 15.5782 17.6098 15.719 17.469L20.4395 12.75H2.75C2.55109 12.75 2.36032 12.671 2.21967 12.5303C2.07902 12.3896 2 12.1989 2 12Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>

            {/* Display Response Message */}
            {responseMessage && (
              <div className='response-message'>
                {responseMessage}
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ContactForm;

import React, { useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from 'react-router-dom';
import FooterCommonCTA from '../components/FooterCommonCTA';

import "./work.scss";

import workAnnapoorna from "../images/page-work/madarth-work-annapoorna.png";
import workAnnapoornaVideo from "../images/page-work/madarth-work-annapoorna.mp4";
import workAha from "../images/page-work/madarth-work-aha.png";
import workAhaVideo from "../images/page-work/madarth-home-page-work-aha.mp4";
import workCSK from "../images/page-work/madarth-work-chennai-super-kings.png";
import workCSKVideo from "../images/page-work/madarth-work-chennai-super-kings.mp4";
import workRace from "../images/page-work/madarth-work-race.jpg";
import workAmul from "../images/page-work/madarth-work-amul.png";
import workAmulVideo from "../images/page-work/madarth-work-amul.mp4";
import workNTTFGIF from "../images/page-work/madarth-home-page-work-nttf.gif";
import workSundariSilks25 from "../images/page-work/madarth-work-sundarisilks-25-years.png";
import workSundariSilks25Video from "../images/page-work/madarth-home-page-work-sundarisilks.mp4";
import workNova from "../images/page-work/madarth-work-nova.png";
import workNovaVideo from "../images/page-work/madarth-work-nova.mp4";
import workJSK from "../images/page-work/madarth-work-joburg-super-kings.png";
import workJSKVideo from "../images/page-work/madarth-work-joburg-super-kings.mp4";
import workSundariSilksMargazhi from "../images/page-work/madarth-work-sundarisilks-margazhi-utsav.png";
import workSundariSilksMargazhiVideo from "../images/page-work/madarth-work-sundarisilks-margazhi-utsav.mp4";

function Work() {
  // Work items data
  const workItems = [
    {
      id: 1,
      to: '/work/annpoorna-masala',
      imageType: 'video',
      imageSrc: workAnnapoornaVideo,
      poster: workAnnapoorna,
      ariaLabel: 'Annapoorna',
      title: 'Annapoorna',
      tag: ' Love is contagious, so is Annapoorna\'s rebranding revolution!',
    },
    {
      id: 2,
      to: '/work/aha',
      imageType: 'video',
      imageSrc: workAhaVideo,
      poster: workAha,
      ariaLabel: 'Aha',
      title: 'AHA',
      tag: 'When Tamilnadu went Aha!',
    },
    {
      id: 3,
      to: '/work/chennai-super-kings',
      imageType: 'video',
      imageSrc: workCSKVideo,
      poster: workCSK,
      ariaLabel: 'Chennai Super Kings',
      title: 'Chennai Super Kings',
      tag: 'Whistle Podu, yet again!',
    },
    {
      id: 4,
      to: '/work/race-ayirathil-oru-kathai',
      imageType: 'image',
      imageSrc: workRace,
      ariaLabel: 'Race',
      title: 'Race',
      tag: 'In Ayirathil Oru Kadhai, we crafted belief, one story at a time',
    },
    {
      id: 5,
      to: '/work/amul',
      imageType: 'video',
      imageSrc: workAmulVideo,
      poster: workAmul,
      ariaLabel: 'Amul',
      title: 'Amul',
      tag: "And this is how Amul found its flavour in TamilNadu",
    },
    {
      id: 6,
      to: '/work/nttf',
      imageType: 'image',
      imageSrc: workNTTFGIF,
      ariaLabel: 'NTTF',
      title: 'NTTF',
      tag: 'Brewed to perfection: A coffee table book and a corporate launch amidst the covid chaos',
    },
    {
      id: 7,
      to: '/work/sundari-silks-25-years',
      imageType: 'video',
      imageSrc: workSundariSilks25Video,
      poster: workSundariSilks25,
      ariaLabel: 'Sundari Silks',
      title: 'Sundari Silks',
      tag: '25 years of legacy woven in silk and stories',
    },
    {
      id: 8,
      to: '/work/nova',
      imageType: 'video',
      imageSrc: workNovaVideo,
      poster: workNova,
      ariaLabel: 'Nova',
      title: 'NOVA',
      tag: 'Where double the ‘Yes’ means double the sales!',
    },
    {
      id: 9,
      to: '/work/joburg-super-kings',
      imageType: 'video',
      imageSrc: workJSKVideo,
      poster: workJSK,
      ariaLabel: 'Joburg Super Kings',
      title: 'Joburg Super Kings',
      tag: "Game plan revealed: Bringing the Super Kings closer to Joburg",
    },
    {
      id: 10,
      to: '/work/sundari-silks-margazhi-utsavam',
      imageType: 'video',
      imageSrc: workSundariSilksMargazhiVideo,
      poster: workSundariSilksMargazhi,
      ariaLabel: 'Sundari Silks Margazhi Utsav',
      title: 'Sundari Silks',
      tag: 'Strings, Songs & Silks: A Margazhi Utsav to Remember',
    },
  ];

  // Shuffle the work items
  const shuffledWorkItems = useMemo(() => {
    const items = [...workItems];
    for (let i = items.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [items[i], items[j]] = [items[j], items[i]];
    }
    return items;
  }, []);

  return (
    <div>
      <Helmet>
        <title>Portfolio | MADARTH® | Branding, Advertising, Design &amp; Digital Marketing Agency India</title>
        <meta
          name="description"
          content="Madarth is a branding, advertising, design and digital marketing agency. We create compelling and relevant solutions for our clients, across verticals."
        />
        <link rel="canonical" href="https://www.madarth.com/work/" />
      </Helmet>
      <div className="page--Work">
        <section className="grid__box pageHero ">
          <h1 className="pageHero__title">
            Unified approach <br /> with creative <br /> synergy
          </h1>
          <p className="pageHero__content">
            We understand your cultural roots and turn them into powerful stories.
            We celebrate big ideas and make them work across channels. Our secret
            sauce? Let’s talk over a steaming cup of filter coffee. 😉
          </p>
        </section>
        <section className="grid__box work--lists">
          {shuffledWorkItems.map((item) => (
            <Link to={item.to} className="work--list" key={item.id}>
              <div className="work--list__image">
                {item.imageType === 'video' ? (
                  <video
                    width="100%"
                    height="100%"
                    preload="auto"
                    poster={item.poster}
                    autoPlay
                    muted
                    loop
                    playsInline
                    aria-label={item.ariaLabel}
                    className="slideContentObject"
                    style={{
                      objectFit: 'cover',
                      display: 'block',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <source src={item.imageSrc} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img src={item.imageSrc} alt={item.ariaLabel} />
                )}
              </div>
              <div className="work--list__content">
                <h3 className="work--list__content--title"> {item.title} </h3>
                <h3 className="work--list__content--tag"> {item.tag} </h3>
              </div>
            </Link>
          ))}
        </section>
        <FooterCommonCTA
          content="Tell us your story. We’d love to hear from you."
          linkText="Let’s talk"
          linkUrl="/contact-us"
        />
      </div>
    </div>
  );
}

export default Work;

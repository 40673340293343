import React from "react";
import { Link } from 'react-router-dom';
import PropTypes from "prop-types"; // To define prop types (optional)

const FooterCommonCTA = ({
  content = "If you have a business, we’ll help turn it into a brand. And a world-class one at that.",
  linkText = "Let’s connect!",
  linkUrl = "/contact-us"
}) => {
  return (
    <section className="common--cta">
      <div className="grid__box">
        <p>{content}</p>
        <Link to={linkUrl}>{linkText}</Link>
      </div>
    </section>
  );
};

// Define prop types (optional)
FooterCommonCTA.propTypes = {
  content: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
};

export default FooterCommonCTA;

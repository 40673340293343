import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";

import FooterCommonCTA from '../../components/FooterCommonCTA';


import jskCover from "../../images/work/jsk/image/jsk-inner-cover.png";
import jsk1 from "../../images/work/jsk/image/jsk-1.png";
import jsk2 from "../../images/work/jsk/image/jsk-2.png";
import jsk3 from "../../images/work/jsk/image/jsk-3.png";
import jsk4 from "../../images/work/jsk/image/jsk-4.png";
import jsk5 from "../../images/work/jsk/image/jsk-5.png";
import jsk6 from "../../images/work/jsk/image/jsk-6.png";
import jsk7 from "../../images/work/jsk/image/jsk-7.png";
import jsk8 from "../../images/work/jsk/image/jsk-8.png";
import jsk9 from "../../images/work/jsk/image/jsk-9.png";
import jsk10 from "../../images/work/jsk/image/jsk-10.png";


import jskVideoCover1 from "../../images/work/jsk/video-cover/jsk-1.png";
import jskVideoCover2 from "../../images/work/jsk/video-cover/jsk-2.png";

import jskVideo1 from "../../images/work/jsk/video/jsk-1.mp4";
import jskVideo2 from "../../images/work/jsk/video/jsk-2.mp4";

function JoburgSuperKings() {

    useEffect(() => {
        // Add the custom class to the body
        document.body.classList.add('header-normal');

        // Cleanup function to remove the class when component unmounts
        return () => {
            document.body.classList.remove('header-normal');
        };
    }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount
    return (
        <div>
            <Helmet>
                <title>
                    Joburg Super Kings
                </title>
                <meta
                    name="description"
                    content="Madarth is a branding, advertising, design and digital marketing agency. We create compelling and relevant solutions for our clients, across verticals."
                />
                <link rel="canonical" href="https://www.madarth.com/work/joburg-super-kings/" />
            </Helmet>
            <section className="project__detail">
                <div className="grid__box">

                    <div className="section__title--content">
                        <div className="text">
                            <h1 className="project__detail--title">Joburg Super Kings </h1>
                            <p className="project__detail--description">Inception, Core Branding and Omnichannel Distribution for Joburg Super Kings.</p>
                            <p className="project__detail--tag">
                                <span>Omni channel Strategy</span> |
                                <span>Sports Marketing</span>
                            </p>
                        </div>
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={jskCover} alt="" />
                    </div>
                    {/* <div className="section__title--content">
                    <div className="title">
                        <h2 className="project__detail--year">2024</h2>
                    </div>
                    <div className="text">
                        <h1 className="project__detail--title">Joburg Super Kings </h1>
                        <p className="project__detail--description">Inception, Core Branding and Omnichannel Distribution for Joburg Super Kings. 
                        </p>
                    </div>
                </div> */}
                    <div className="section__content">
                        <div className="title">
                            <p className="project__detail--type">Omnichannel campaign</p>
                        </div>
                        <div className="text">
                            <p>Our client Chennai Super Kings Cricket Ltd., has enjoyed a global fandom for its brand of cricket the team CSK has displayed at the Indian Premier League over the years, winning 5 championships till date.</p>
                            <p>With a solid record for the brand, they set out on global expansion for taking their cricket to the world over. As its first step, Joburg Super Kings was drafted in the SA20 League, South Africa in July 2022. </p>
                        </div>
                    </div>
                    <div className="section__single padding:bottom:0px">
                        <img loading="lazy" src={jsk1} alt="" />
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={jsk2} alt="" />
                    </div>
                    <div className="section__content">
                        <div className="title"><p className="project__detail--type">Core Branding</p></div>
                        <div className="text">
                            <p>As part of the initial pitch and draft, Madarth partnered up with CSK CL to create the name, logo and jersey kit docket for the newly bought club. </p>
                            <p>Madarth set out in capturing the essence of the club CSK as the brand’s pedigree and thereby created team logo and jersey options that have the brand recognition and yet stand unique and true to the South African spirit of cricket by blending the Indian grown brands values with the sensibilities of South Africa.</p>
                            <p>As the natural extension of the CSK IP Whistle Podu, the IP Whistle For Joburg  was created to bring out the superfans to cheer for the Super Kings of Johannesburg.</p>
                        </div>
                    </div>
                    <div className="section__single">
                        <video src={jskVideo1} poster={jskVideoCover1} autoPlay loop
                            muted preload="auto" width="640" height="360" playsInline className="width:100 height:auto">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="section__single padding:top:0px ">
                        <img loading="lazy" src={jsk3} alt="" />
                    </div>
                    <div className="section__single padding:top:0px margin:top:-1px">
                        <img loading="lazy" src={jsk4} alt="" />
                    </div>
                    <div className="section__single">
                        <video src={jskVideo2} poster={jskVideoCover2} autoPlay loop
                            muted preload="auto" width="640" height="360" playsInline className="width:100 height:auto">
                            Your browser does not support the video tag.
                        </video>
                    </div>

                    <div className="section__content">
                        <div className="title"><p className="project__detail--type">2023,2024 The Experience</p></div>
                        <div className="text">
                            <p>With the first two seasons, a solid messaging of local affinity was carried forward with the execution of the season campaigns ‘Joyburg’ and ‘To Joburg we belong’  generating local interest by school visits, local carnival, Graffiti fan wall creation to take the sport beyond the grounds to the Joburg public.</p>
                            <p>Spatial branding on the player hotels, buses and the Wanderers stadium were done by blending in the visuals of the team, fans and graffiti elements for the public to relate and appreciate.</p>
                            <p>On socials, we captured the fanfare for the sport and the celebration the brand stood for as the matches progressed leading to worldwide attention to the league and thereby becoming the most talked about brand in the cricket world. </p>
                        </div>
                    </div>

                    <div className="section__single">
                        <img loading="lazy" src={jsk5} alt="" />
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={jsk6} alt="" />
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={jsk7} alt="" />
                    </div>

                    <div className="section__single">
                        <img loading="lazy" src={jsk8} alt="" />
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={jsk9} alt="" />
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={jsk10} alt="" />
                    </div>
                </div>
            </section>
            <FooterCommonCTA
                content="Tell us your story. We’d love to hear from you."
                linkText="Let’s talk"
                linkUrl="/contact-us"
            />
        </div>
    );
}

export default JoburgSuperKings;

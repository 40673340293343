import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import './SlidingGallery.scss';

import workAnnapoorna from "../images/page-home/work/madarth-home-page-work-annapoorna.jpg";
import workAha from "../images/page-home/work/madarth-home-page-work-aha.png";
import workAhaVideo from "../images/page-home/work/madarth-home-page-work-aha.mp4";
import workJSK from "../images/page-home/work/madarth-work-joburg-super-kings.png";
import workJSKVideo from "../images/page-home/work/madarth-work-joburg-super-kings.mp4";
import workSundariSilks from "../images/page-home/work/madarth-home-page-work-sundarisilks.jpg";
import workSundariSilksVideo from "../images/page-home/work/madarth-home-page-work-sundarisilks.mp4";
import workAmul from "../images/page-home/work/madarth-home-page-work-amul.jpg";
import workNTTFGIF from "../images/page-home/work/madarth-home-page-work-nttf.gif";
import { Link } from 'react-router-dom';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const SlidingGallery = () => {
    const wrapperEl = useRef(null);
    const minimapRef = useRef(null);
    const slideRefs = useRef([]);
    const [activeSlide, setActiveSlide] = useState(0);
    const [isMinimapActive, setMinimapActive] = useState(false);
    // Define an offset value in pixels
    const offsetValue = 50; // Adjust this value to control when the trigger occurs
    useEffect(() => {
        // Preload images and GIFs
        const imageSources = [workAnnapoorna, workAha, workJSK, workSundariSilks, workAmul, workNTTFGIF];
        imageSources.forEach(src => {
            const img = new Image();
            img.src = src;
        });
        // Preload videos
        const videoSources = [workAhaVideo, workJSKVideo, workSundariSilksVideo];
        videoSources.forEach(videoSrc => {
            const video = document.createElement('video');
            video.src = videoSrc;
            video.preload = 'auto';
        });

        const slides = slideRefs.current.filter(slide => slide); // Filter out any undefined slides
        const isMobile = window.innerWidth <= 768;

        if (isMobile) {
            slides.forEach(slide => {
                gsap.set(slide, { clearProps: "all" });
            });
            return;
        }

        // Scroll animation setup for larger screens
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: wrapperEl.current,
                start: "top top",
                end: `+=${slides.length * 100}%`,
                scrub: true,
                pin: true,
                markers: false,
                onUpdate: (self) => {
                    const index = Math.floor(self.progress * slides.length);
                    setActiveSlide(index);
                },
                onEnter: () => {
                    console.log('Enter');
                    if (window.scrollY >= wrapperEl.current.getBoundingClientRect().bottom - offsetValue) {
                        setMinimapActive(true); // Activate minimap when gallery enters viewport
                    }
                },
                onLeave: () => {
                    console.log('Leave');
                    if (window.scrollY >= wrapperEl.current.getBoundingClientRect().bottom - offsetValue) {
                        setMinimapActive(false); // Deactivate minimap when gallery leaves viewport with offset
                    }
                },
                onEnterBack: () => {
                    console.log('Enter back');
                    console.log(window.screenY, wrapperEl.current.getBoundingClientRect().bottom - offsetValue);
                    if (window.scrollY >= wrapperEl.current.getBoundingClientRect().bottom - offsetValue) {
                    setMinimapActive(true); // Reactivate minimap on scrolling back
                    }
                },
                onLeaveBack: () => {
                    console.log('Leave back');
                    // Add offset value to deactivate minimap a bit later
                    if (window.scrollY >= wrapperEl.current.getBoundingClientRect().bottom - offsetValue) {
                        setMinimapActive(false); // Deactivate minimap on scrolling back past the top with offset
                    }
                }
            },
            defaults: { ease: "none" }
        });

        // Initial state setup
        slides.forEach((slide, idx) => {
            if (!slide) return; // Ensure slide exists
            if (idx === 0) return;
            const slideImage = slide.querySelector(".slideContent .slideContentObject");
            if (slideImage) {
                gsap.set(slide, { yPercent: 100 });
                gsap.set(slideImage, { yPercent: -100 });
            }
        });

        slides.forEach((slide, idx) => {
            if (!slide || idx === slides.length - 1) return;
            const slideImg = slide.querySelector(".slideContentObject");
            const nextSlide = slides[idx + 1];
            if (!nextSlide) return; // Ensure next slide exists
            const nextSlideImg = nextSlide.querySelector(".slideContentObject");

            tl.to(slide, { yPercent: -100 })
                .to(slideImg, { yPercent: 100 }, "<")
                .to(nextSlide, { yPercent: 0 }, "<")
                .to(nextSlideImg, { yPercent: 0 }, "<");
        });

        // Clean up on component unmount
        return () => {
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        };
    }, []);

    return (
        <div>
            <div ref={wrapperEl} className="wrapper">
                <section className="slide" ref={el => slideRefs.current[0] = el} key={0}>
                    <div className="slideContent">
                        <Link to="/work/annpoorna-masala">
                            <img className="slideContentObject" src={workAnnapoorna} alt="" />
                        </Link>
                    </div>
                </section>
                <section className="slide" ref={el => slideRefs.current[1] = el} key={1}>
                    <div className="slideContent">
                        <Link to="/work/aha">
                            <video
                                width="100%"
                                height="100%"
                                preload="auto"
                                poster={workAha}
                                autoPlay
                                muted
                                loop
                                playsInline
                                aria-label="Aha"
                                className="slideContentObject"
                                style={{
                                    objectFit: "cover",
                                    display: "block",
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                <source src={workAhaVideo} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </Link>
                    </div>
                </section>
                <section className="slide" ref={el => slideRefs.current[2] = el} key={2}>
                    <div className="slideContent">
                        <Link to="/work/sundari-silks-25-years">
                            <video
                                width="100%"
                                height="100%"
                                preload="auto"
                                poster={workSundariSilks}
                                autoPlay
                                muted
                                loop
                                playsInline
                                aria-label="Sundari Silks"
                                className="slideContentObject"
                                style={{
                                    objectFit: "cover",
                                    display: "block",
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                <source src={workSundariSilksVideo} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </Link>
                    </div>
                </section>
                <section className="slide" ref={el => slideRefs.current[3] = el} key={3}>
                    <div className="slideContent">
                        <Link to="/work/joburg-super-kings">
                            <video
                                width="100%"
                                height="100%"
                                preload="auto"
                                poster={workJSK}
                                autoPlay
                                muted
                                loop
                                playsInline
                                aria-label="Joburg Super Kings"
                                className="slideContentObject"
                                style={{
                                    objectFit: "cover",
                                    display: "block",
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                <source src={workJSKVideo} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </Link>
                    </div>
                </section>
                <section className="slide" ref={el => slideRefs.current[4] = el} key={4}>
                    <div className="slideContent">
                        <Link to="/work/amul">
                            <img className="slideContentObject" src={workAmul} alt="" />
                        </Link>
                    </div>
                </section>
                <section className="slide" ref={el => slideRefs.current[5] = el} key={5}>
                    <div className="slideContent">
                        <Link to="/work/nttf">
                            <img className="slideContentObject" src={workNTTFGIF} alt="" />
                        </Link>
                    </div>
                </section>
            </div>

            <div ref={minimapRef} className={`minimap ${isMinimapActive ? 'active' : ''}`}>
                {slideRefs.current.map((slide, index) => {
                    const contentElement = slide?.querySelector('.slideContentObject');
                    return (
                        <div
                            key={index}
                            className={`minimapItem ${index === activeSlide ? 'active' : ''}`}
                        >
                            {contentElement?.tagName === 'IMG' && (
                                <img
                                    src={contentElement.src}
                                    alt={`Slide ${index}`}
                                    className="minimapThumbnail"
                                />
                            )}
                            {contentElement?.tagName === 'VIDEO' && (
                                <video
                                    src={contentElement.querySelector('source')?.src}
                                    poster={contentElement.getAttribute('poster')}
                                    className="minimapThumbnail"
                                    muted
                                    loop
                                    autoPlay
                                />
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default SlidingGallery;
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";

import FooterCommonCTA from '../../components/FooterCommonCTA';

import ahaCover from "../../images/work/aha/image/aha-cover.png";
import ahaBusStandAdAd from "../../images/work/aha/image/aha-bus-stand-ad.png";
// import ahaBusStandAdTwo from "../../images/work/aha/image/aha-bus-stand-ad-two.png";
import ahaNewsPapperAd from "../../images/work/aha/image/aha-news-papper-ad.png";

// import ahaVideo1 from "../../images/work/aha/video/aha-1.mp4";
import ahaVideo2 from "../../images/work/aha/video/aha-2.mp4";
import ahaVideo3 from "../../images/work/aha/video/aha-3.mp4";
// import ahaVideo4 from "../../images/work/aha/video/aha-4.mp4";
import ahaVideo5 from "../../images/work/aha/video/aha-5.mp4";
import ahaVideo6 from "../../images/work/aha/video/aha-6.mp4";
// import ahaVideo7 from "../../images/work/aha/video/aha-7.mp4";
import ahaVideo8 from "../../images/work/aha/video/aha-8.mp4";
import ahaVideo9 from "../../images/work/aha/video/aha-9.mp4";

// import ahaVideoCover1 from "../../images/work/aha/video-cover/aha-1.png";
import ahaVideoCover2 from "../../images/work/aha/video-cover/aha-2.png";
import ahaVideoCover3 from "../../images/work/aha/video-cover/aha-3.png";
// import ahaVideoCover4 from "../../images/work/aha/video-cover/aha-4.png";
import ahaVideoCover5 from "../../images/work/aha/video-cover/aha-5.png";
import ahaVideoCover6 from "../../images/work/aha/video-cover/aha-6.png";
// import ahaVideoCover7 from "../../images/work/aha/video-cover/aha-7.png";
import ahaVideoCover8 from "../../images/work/aha/video-cover/aha-8.png";
import ahaVideoCover9 from "../../images/work/aha/video-cover/aha-9.png";

function Aha() {
    useEffect(() => {
        // Add the custom class to the body
        document.body.classList.add('header-normal');

        // Cleanup function to remove the class when component unmounts
        return () => {
            document.body.classList.remove('header-normal');
        };
    }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount
    return (
        <div>
            <Helmet>
                <title>
                    AHA
                </title>
                <meta
                    name="description"
                    content="Madarth is a branding, advertising, design and digital marketing agency. We create compelling and relevant solutions for our clients, across verticals."
                />
                <link rel="canonical" href="https://www.madarth.com/work/aha/" />
            </Helmet>
            <section className="project__detail">
                <div className="grid__box">
                    <div className="section__title--content">
                        <div className="text">
                            <h1 className="project__detail--title">Aha</h1>
                            <p className="project__detail--description">100% Tamil Nadu, 200% Entertainment</p>
                            <p className="project__detail--tag">
                                <span>Omni channel campaign</span> |
                                <span>Entertainment</span>
                            </p>
                        </div>
                    </div>
                    <div className="section__single aspect-ratio">
                        <div className="aspect-wrapper">
                            <img src={ahaCover} alt="" />
                        </div>
                    </div>
                    <div className="section__content">
                        <div className="title">
                            <p className="project__detail--type">Omni channel campaign</p>
                        </div>
                        <div className="text">
                            <p>aha 100% Tamil web series and movies', is an OTT streaming service that offers exclusive
                                Tamil content. With a content slate of 52 titles a year, aha has significant plans for new
                                releases every Friday. aha needed to position itself uniquely in order to stand out and be
                                acknowledged in an already cluttered OTT space. As a relatively unknown player, with limited
                                available content at the time, the success of the brand’s launch in Tamil Nadu heavily
                                relied on the efficacy of a clutter-breaking marketing campaign.</p>
                            <p>aha's unique selling proposition of '100%Tamil' was a great place to start. And a natural
                                next step would be to build on the audience’s love of their mother tongue. It elevated the
                                whole pitch to another level as it set the ground to build kindred and sense of belonging
                                towards aha.</p>
                            <p>We came up with a campaign  “THATTUNA THAMIZH MATTUME” meaning “Tap and all you’ll see is
                                Tamil”. The campaign has an emotional aspect where aha enables a connection between the
                                Tamil audience and the content they love.</p>
                        </div>
                    </div>
                    <div className="section__single">
                        <div className="ratio ratio-16x9 padding:top:20px">
                            <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/8Riiv9Y1ruc?si=vtcUaOIUiXLu31IO&rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="section__two">
                        <video src={ahaVideo2} poster={ahaVideoCover2} autoPlay loop
                            muted preload="auto" width="640" height="360" playsInline className="width:100 height:auto">
                            Your browser does not support the video tag.
                        </video>
                        <video src={ahaVideo3} poster={ahaVideoCover3}
                            autoPlay loop muted preload="auto" width="640" height="360" playsInline
                            className="width:100 height:auto">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="section__single padding:top:20px">
                        <div className="ratio ratio-16x9" >
                            <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/sHxNZNOeUog?si=vL95T_UWzMnKsFXc&rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoPlay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        </div>
                    </div>
                    <div className="section__two">
                        <video src={ahaVideo5} poster={ahaVideoCover5} autoPlay loop
                            muted preload="auto" width="640" height="360" playsInline className="width:100 height:auto">
                            Your browser does not support the video tag.
                        </video>
                        <video src={ahaVideo6} poster={ahaVideoCover6}
                            autoPlay loop muted preload="auto" width="640" height="360" playsInline
                            className="width:100 height:auto">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="section__single padding:top:20px">
                        <div className="ratio ratio-16x9 ">
                            <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/WpHD5VTeAng?si=F9EfMjubOGtOUykT&rel=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoPlay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        </div>
                    </div>
                    <div className="section__two">
                        <video src={ahaVideo8} poster={ahaVideoCover8} autoPlay loop
                            muted preload="auto" width="640" height="360" playsInline className="width:100 height:auto">
                            Your browser does not support the video tag.
                        </video>
                        <video src={ahaVideo9} poster={ahaVideoCover9}
                            autoPlay loop muted preload="auto" width="640" height="360" playsInline
                            className="width:100 height:auto">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="section__content">
                        <div className="text">
                            <p>Considering the media that the target audience were likely to consume, we rolled out 2
                                television commercials, featuring aha’s two brand ambassadors- Movie Star STR and Music
                                Director Anirudh.</p>
                            <p>The campaign demonstrated how the app and the 100% Tamil content in it, connects the hearts
                                of the people with just a tap, thus bringing them together.</p>
                        </div>
                    </div>
                    <div className="section__single aspect-ratio">
                        <div className="aspect-wrapper">
                            <img loading="lazy" src={ahaBusStandAdAd} alt="" />
                        </div>
                    </div>
                    <div className="section__single aspect-ratio">
                        <div className="aspect-wrapper">
                            <img loading="lazy" src={ahaNewsPapperAd} alt="" />
                        </div>
                    </div>
                    <div className="section__content">
                        <div className="text">
                            <p>We leveraged the campaign on various platforms, including OOH, print, and digital, resulting
                                in a 4x increase in subscription rates</p>
                        </div>
                    </div>
                </div>
            </section>
            <FooterCommonCTA
                content="Tell us your story. We’d love to hear from you."
                linkText="Let’s talk"
                linkUrl="/contact-us"
            />
        </div>
    );
}

export default Aha;

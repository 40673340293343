import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import FooterCommonCTA from '../../components/FooterCommonCTA';

import annapoornaMasalaTVC1 from "../../images/work/annapoorna-masala/video/annapoorna-tv-1.mp4";
import annapoornaMasalaTVC2 from "../../images/work/annapoorna-masala/video/annapoorna-tv-2.mp4";
import annapoornaMasalaVideo1 from "../../images/work/annapoorna-masala/video/annapoorna-1.mp4";
import annapoornaMasalaVideo2 from "../../images/work/annapoorna-masala/video/annapoorna-2.mp4";
import annapoornaMasalaVideo3 from "../../images/work/annapoorna-masala/video/annapoorna-3.mp4";
import annapoornaMasalaVideo6 from "../../images/work/annapoorna-masala/video/annapoorna-6.mp4";
import annapoornaMasalaVideo7 from "../../images/work/annapoorna-masala/video/annapoorna-7.mp4";
import annapoornaMasalaVideo8 from "../../images/work/annapoorna-masala/video/annapoorna-8.mp4";

import annapoornaMasalaVideoCover1 from "../../images/work/annapoorna-masala/video-cover/annapoorna-1.png";
import annapoornaMasalaVideoCover2 from "../../images/work/annapoorna-masala/video-cover/annapoorna-2.png";
import annapoornaMasalaVideoCover3 from "../../images/work/annapoorna-masala/video-cover/annapoorna-3.png";
import annapoornaMasalaVideoCover6 from "../../images/work/annapoorna-masala/video-cover/annapoorna-6.png";
import annapoornaMasalaVideoCover7 from "../../images/work/annapoorna-masala/video-cover/annapoorna-7.png";
import annapoornaMasalaVideoCover8 from "../../images/work/annapoorna-masala/video-cover/annapoorna-8.png";

import annapoornaMasala1 from "../../images/work/annapoorna-masala/image/annapoorna-masala-1.png";
import annapoornaMasala2 from "../../images/work/annapoorna-masala/image/annapoorna-masala-2.png";
import annapoornaMasala3 from "../../images/work/annapoorna-masala/image/annapoorna-masala-3.png";
// import annapoornaMasala4 from "../../images/work/annapoorna-masala/image/annapoorna-masala-4.png";
import annapoornaMasala5 from "../../images/work/annapoorna-masala/image/annapoorna-masala-5.png";
// import annapoornaMasala6 from "../../images/work/annapoorna-masala/image/annapoorna-masala-6.png";
import annapoornaMasala7 from "../../images/work/annapoorna-masala/image/annapoorna-masala-7.png";
// import annapoornaMasala8 from "../../images/work/annapoorna-masala/image/annapoorna-masala-8.png";
// import annapoornaMasala9 from "../../images/work/annapoorna-masala/image/annapoorna-masala-9.png";
import annapoornaMasala9Gif from "../../images/work/annapoorna-masala/image/annapoorna-masala-9.gif";
import annapoornaMasala10 from "../../images/work/annapoorna-masala/image/annapoorna-masala-10.png";
import annapoornaMasala11 from "../../images/work/annapoorna-masala/image/annapoorna-masala-11.png";
import annapoornaMasala12 from "../../images/work/annapoorna-masala/image/annapoorna-masala-12.png";
// import annapoornaMasala13 from "../../images/work/annapoorna-masala/image/annapoorna-masala-13.png";

function AnnapoornaMasala() {
    useEffect(() => {
        // Add the custom class to the body
        document.body.classList.add('header-normal');

        // Cleanup function to remove the class when component unmounts
        return () => {
            document.body.classList.remove('header-normal');
        };
    }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount
    return (
        <div>
            <Helmet>
                <title>
                    Annapoorna Masala
                </title>
                <meta
                    name="description"
                    content="Madarth is a branding, advertising, design and digital marketing agency. We create compelling and relevant solutions for our clients, across verticals."
                />
                <link rel="canonical" href="https://www.madarth.com/work/annpoorna-masala/" />
            </Helmet>
            <section className="project__detail">
                <div className="grid__box">
                    <div className="section__title--content">
                        <div className="text">
                        <h1 className="project__detail--title">Annapoorna</h1>
                            <p className="project__detail--description">Expanding into Modern Trade and Global Markets</p>
                            <p className="project__detail--tag">
                                <span>Omni channel campaign</span> | 
                                <span>Culinary Marketing</span>
                            </p>
                        </div>
                    </div>
                    <div className="section__single">
                        <div className="aspect-ratio-16-9">
                            <img src="https://ik.imagekit.io/pixelboy/annapoorna/annapoorna-masala-cover.webp?updatedAt=1724840316107" alt="" />
                        </div>
                    </div>
                    <div className="section__content">
                        <div className="title">
                            <p className="project__detail--type">Omni channel campaign</p>
                        </div>
                        <div className="text">
                            <p>Established in 1975, Annapoorna Masalas and Spices is a pioneer in the art of spice
                                processing and packaging. Backed by a legacy of four decades and a repertoire of traditional
                                recipes handed down over generations, Annapoorna Masalas and Spices are packed with fresh
                                flavours and fondness.</p>
                            <p>The brand aimed to expand into modern trade and export markets while strengthening its
                                current retail and distribution network. With this in mind, and understanding the brand’s
                                legacy, we proposed a comprehensive rebranding strategy to position Annapoorna as a modern
                                yet authentic national brand.</p>
                        </div>
                    </div>
                    <div className="section__single padding:bottom:0px">
               <video src={annapoornaMasalaTVC1} poster={annapoornaMasalaVideoCover1} autoPlay loop
               muted preload="auto" width="640" height="360" playsInline className="width:100 height:auto">
               Your browser does not support the video tag.
               </video>
                    </div>
                    <div className="section__two padding:bottom:0px">
                        <video src={annapoornaMasalaVideo1} poster={annapoornaMasalaVideoCover1} autoPlay loop
                            muted preload="auto" width="640" height="360" playsInline className="width:100 height:auto">
                            Your browser does not support the video tag.
                        </video><video src={annapoornaMasalaVideo2} poster={annapoornaMasalaVideoCover2}
                            autoPlay loop muted preload="auto" width="640" height="360" playsInline
                            className="width:100 height:auto">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="section__content">
                        <div className="title">
                            <p className="project__detail--type">Rebranding: The Taste That Brings People Together</p>
                        </div>
                        <div className="text">
                            <p>Our approach centered around the idea that food brings people together, transcending age and
                                regional differences. Recognising food as more than just sustenance, we aimed to highlight
                                its role in creating emotional connections and celebrating cultural diversity.</p>
                            <p>We introduced refreshed packaging to mark a new chapter for the brand while honouring its
                                heritage. This redesign was integral to our strategy and showcased characters from different
                                regions and walks of life enjoying the process of cooking and eating together. This visual
                                representation reinforced the message that Annapoorna’s products are for everyone,
                                celebrating the diversity of Indian cuisine. The new packaging, coupled with our slogan "The
                                Taste That Unites," effectively communicated the essence of Annapoorna's mission — bringing
                                people together through authentic regional flavours.</p>
                        </div>
                    </div>
                    <div className="section__single">
                        
               <video src={annapoornaMasalaTVC2} poster={annapoornaMasalaVideoCover7} autoPlay loop
               muted preload="auto" width="640" height="360" playsInline className="width:100 height:auto">
               Your browser does not support the video tag.
               </video>
                    </div>
                    <div className="section__two">
                        <video src={annapoornaMasalaVideo3} poster={annapoornaMasalaVideoCover3} autoPlay loop
                            muted preload="auto" width="640" height="360" playsInline className="width:100 height:auto">
                            Your browser does not support the video tag.
                        </video>
                        <video src={annapoornaMasalaVideo7} poster={annapoornaMasalaVideoCover7}
                            autoPlay loop muted preload="auto" width="640" height="360" playsInline
                            className="width:100 height:auto">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="section__content">
                        <div className="title"><p className="project__detail--type">TVC Launch: From Our Kitchen to Yours</p></div>
                        <div className="text">
                            <p>To further amplify our rebranding, we launched two TVCs under the themes "Share Love. It's Contagious" and "Return Love. It's Contagious." Featuring Annapoorna Paati, a grandmother figure, these commercials emphasized the brand’s rich heritage and connection to traditional cooking. The choice of this character was designed to resonate emotionally with audiences across generations, highlighting the warmth and care that define Annapoorna’s products.</p>
                        </div>
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={annapoornaMasala1} alt="" />
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={annapoornaMasala2} alt="" />
                    </div>
                    <div className="section__content">
                        <div className="title"><p className="project__detail--type">Navigating the Pandemic: Annapoorna Utsavam Aarambam</p></div>
                        <div className="text">
                            <p>With the onset of the pandemic, we pivoted to a virtual launch strategy for Annapoorna Utsavam Aarambam. This involved a media and channel partner briefing session in Coimbatore, followed by a comprehensive online event. Despite the restrictions, our virtual approach ensured extensive engagement and coverage, leveraging media and distribution channels effectively.</p>
                        </div>
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={annapoornaMasala3} alt="" />
                    </div>
                    <div className="section__single">
                        <video src={annapoornaMasalaVideo6} poster={annapoornaMasalaVideoCover6} autoPlay loop
                            muted preload="auto" width="640" height="360" playsInline className="width:100 height:auto">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="section__content">
                        <div className="title"><p className="project__detail--type">Social Media Campaign: Building Bonds Through Food</p></div>
                        <div className="text">
                            <p>Our social media strategy focused on creating content that fostered connections and evoked nostalgia. Posts highlighted the joy of sharing meals, cherished memories, and the warmth of cooking together. By emphasising these emotional aspects, we aimed to reinforce Annapoorna's role in bringing people together and celebrating the richness of Indian cuisine.</p>
                            <p>The rebranding and strategic marketing efforts resulted in a successful market entry. The brand has now made its mark in modern trade channels across Tamil Nadu and has secured export opportunities, reflecting its national presence.</p>
                        </div>
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={annapoornaMasala5} alt="" />
                    </div>
                    <div className="section__single">
                        <video src={annapoornaMasalaVideo8} poster={annapoornaMasalaVideoCover8} autoPlay loop
                            muted preload="auto" width="640" height="360" playsInline className="width:100 height:auto">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="section__single padding:bottom:0px">
                        <img loading="lazy" src={annapoornaMasala7} alt="" />
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={annapoornaMasala9Gif} alt="" />
                    </div>
                    <div className="section__single">
                        <video src={annapoornaMasalaVideo8} poster={annapoornaMasalaVideoCover8} autoPlay loop
                            muted preload="auto" width="640" height="360" playsInline className="width:100 height:auto">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={annapoornaMasala10} alt="" />
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={annapoornaMasala11} alt="" />
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={annapoornaMasala12} alt="" />
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={annapoornaMasala1} alt="" />
                    </div>
                </div>
            </section>
            <FooterCommonCTA
                content="Tell us your story. We’d love to hear from you."
                linkText="Let’s talk"
                linkUrl="/contact-us"
            />
        </div>
    );
}

export default AnnapoornaMasala;

import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";

import FooterCommonCTA from '../../components/FooterCommonCTA';


import amulCover from "../../images/work/amul/image/amul-cover.png";
import amulSocialAdsTwo from "../../images/work/amul/image/amul-social-ads-two.png";
import amulSocialAds from "../../images/work/amul/image/amul-social-ads.png";

import amulVideoCover1 from "../../images/work/amul/video-cover/amul-1.png";
import amulVideoCover2 from "../../images/work/amul/video-cover/amul-2.png";
import amulVideoCover3 from "../../images/work/amul/video-cover/amul-3.png";
import amulVideoCover4 from "../../images/work/amul/video-cover/amul-4.png";

import amulVideo1 from "../../images/work/amul/video/amul-1.mp4";
import amulVideo2 from "../../images/work/amul/video/amul-2.mp4";
import amulVideo3 from "../../images/work/amul/video/amul-3.mp4";
import amulVideo4 from "../../images/work/amul/video/amul-4.mp4";


function Amul() {
    useEffect(() => {
        // Add the custom class to the body
        document.body.classList.add('header-normal');

        // Cleanup function to remove the class when component unmounts
        return () => {
            document.body.classList.remove('header-normal');
        };
    }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount
    return (
        <div>
            <Helmet>
                <title>
                    Amul
                </title>
                <meta
                    name="description"
                    content="Madarth is a branding, advertising, design and digital marketing agency. We create compelling and relevant solutions for our clients, across verticals."
                />
                <link rel="canonical" href="https://www.madarth.com/work/amul/" />
            </Helmet>
            <section className="project__detail">
                <div className="grid__box">

                    <div className="section__title--content">
                        <div className="text">
                            <h1 className="project__detail--title">Amul</h1>
                            <p className="project__detail--description">From Gujarat karan to namooru karan: Journey of Amul in TN</p>
                            <p className="project__detail--tag">
                                <span>Social Media Strategy</span> |
                                <span>FMCG</span>
                            </p>
                        </div>
                    </div>
                    <div className="section__single">
                        <img src={amulCover} alt="" />
                    </div>
                    {/* <div className="section__title--content">
            <div className="title">
                <h2 className="project__detail--year">2021</h2>
            </div>
            <div className="text">
                <h1 className="project__detail--title">Amul</h1>
                <p className="project__detail--description">From Gujarat karan to namooru karan: Journey of Amul in TN</p>
            </div>
           </div> */}
                    <div className="section__content">
                        <div className="title">
                            <p className="project__detail--type">Social Media Strategy</p>
                        </div>
                        <div className="text">
                            <p>Since 1966, AMUL has been known for featuring sensational topical ads. For decades now, people eagerly anticipate the hilarious and witty Amul angle to the nation’s news and controversies.</p>
                            <p>With the advent of social media, this trend snowballed to a whole new level as India began to follow Amul’s magic. In spite of the momentum that social media provided, there was always this thing about Amul having a Hindi bias. This was certainly a setback in non-Hindi-speaking states as Amul was always perceived as an outsider.</p>
                            <p>Enter Madarth. Our job was to transcend the Amul Magic for the Tamil masses. Popular culture in Tamil is exceptionally rich, drawing inspiration from movie dialogues, to lyrics, puns, idioms and proverbs. As a creative agency that understood the land, language and people, we were able to successfully create a brilliant parallel for Amul in Tamil Nadu. With the same pace, fervour and original Tamil flavour, we soon managed to make Amul quintessentially Tamil.</p>
                        </div>
                    </div>
                    <div className="section__single">
                        <video src={amulVideo1} poster={amulVideoCover1} autoPlay loop
                            muted preload="auto" width="640" height="360" playsInline className="width:100 height:auto">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="section__content">
                        <div className="title">
                            <p className="project__detail--type">From indifference to familiarisation</p>
                        </div>
                        <div className="text">
                            <p>We found a subtle way to introduce Hindi product names to the TN audience by associating the product names with Tamil songs and dialogues.</p>
                        </div>
                    </div>
                    <div className="section__single">
                        <video src={amulVideo2} poster={amulVideoCover2} autoPlay loop
                            muted preload="auto" width="640" height="360" playsInline className="width:100 height:auto">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="section__content">
                        <div className="title">
                            <p className="project__detail--type">Translation to regionalisation</p>
                        </div>
                        <div className="text">
                            <p>National topicals transcreated into Tamil without compromising the pun and fun quotient began to make more sense to our audience.</p>
                        </div>
                    </div>
                    <div className="section__single">
                        <video src={amulVideo3} poster={amulVideoCover3} autoPlay loop
                            muted preload="auto" width="640" height="360" playsInline className="width:100 height:auto">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="section__content">
                        <div className="title">
                            <p className="project__detail--type">Original Regional Topicals</p>
                        </div>
                        <div className="text">
                            <p>We also made suggestions that were rolled out to create topicals for popular events happening in TN.</p>
                        </div>
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={amulSocialAds} alt="" />
                    </div>
                    <div className="section__content">
                        <div className="title">
                            <p className="project__detail--type">Pirikka Mudiyathathu: Cinemavum Tamilnadum</p>
                        </div>
                        <div className="text">
                            <p>From waking up till going to bed, pop culture has become a part of TN people’s daily lives.</p>
                            <p>Keeping that in mind, we got on the track to travel with them. From using famous dialogues to using trending audio tracks for reels, we pulled out quite a lot of attraction towards Amul TN.</p>
                        </div>
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={amulSocialAdsTwo} alt="" />
                    </div>
                    <div className="section__content">
                        <div className="title">
                            <p className="project__detail--type">Serndhe iruppathu: Sports’um Tamilnadum</p>
                        </div>
                        <div className="text">
                            <p>Amul partnered with Chennai Super Kings for Cricket and the national teams of Argentina and Portugal during the 2022 World Cup for football.</p>
                            <p>For each team, we ran a campaign throughout the season, focusing on sharing emotions with fans through engaging posts, games, stories, and reels</p>
                        </div>
                    </div>
                    <div className="section__single">
                        <video src={amulVideo4} poster={amulVideoCover4} autoPlay loop
                            muted preload="auto" width="640" height="360" playsInline className="width:100 height:auto">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </section>
            <FooterCommonCTA
                content="Tell us your story. We’d love to hear from you."
                linkText="Let’s talk"
                linkUrl="/contact-us"
            />
        </div>
    );
}

export default Amul;

// RedirectToNotion.js
import { useEffect } from "react";

const RedirectToNotion = () => {
  useEffect(() => {
    window.location.href = "https://joinatmadarth.notion.site/";
  }, []);

  return null;
};

export default RedirectToNotion;

import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";

import FooterCommonCTA from '../../components/FooterCommonCTA';

// import csk1 from "../../images/work/csk/image/csk-1.png";
import csk2 from "../../images/work/csk/image/csk-2.png";
import csk3 from "../../images/work/csk/image/csk-3.png";
import csk4 from "../../images/work/csk/image/csk-4.png";
import csk5 from "../../images/work/csk/image/csk-5.png";
// import csk6 from "../../images/work/csk/image/csk-6.png";
import csk7 from "../../images/work/csk/image/csk-7.png";
import csk8 from "../../images/work/csk/image/csk-8.png";
import csk9 from "../../images/work/csk/image/csk-9.png";
import csk10 from "../../images/work/csk/image/csk-10.png";
import csk11 from "../../images/work/csk/image/csk-11.png";
import csk12 from "../../images/work/csk/image/csk-12.png";
import csk13 from "../../images/work/csk/image/csk-13.png";
import csk14 from "../../images/work/csk/image/csk-14.png";
import csk15 from "../../images/work/csk/image/csk-15.png";
import csk16 from "../../images/work/csk/image/csk-16.png";
import csk17 from "../../images/work/csk/image/csk-17.png";
import csk18 from "../../images/work/csk/image/csk-18.png";
// import csk19 from "../../images/work/csk/image/csk-19.png";
// import csk20 from "../../images/work/csk/image/csk-20.png";
import csk21 from "../../images/work/csk/image/csk-21.png";

import cskVideoCover1 from "../../images/work/csk/video-cover/csk-1.png";
import cskVideoCover2 from "../../images/work/csk/video-cover/csk-2.png";
import cskVideoCover3 from "../../images/work/csk/video-cover/csk-3.png";
import cskVideoCover4 from "../../images/work/csk/video-cover/csk-4.png";

import cskVideo1 from "../../images/work/csk/video/csk-1.mp4";
import cskVideo2 from "../../images/work/csk/video/csk-2.mp4";
import cskVideo3 from "../../images/work/csk/video/csk-3.mp4";
import cskVideo4 from "../../images/work/csk/video/csk-4.mp4";



function ChenniSuperKings() {
    useEffect(() => {
        // Add the custom class to the body
        document.body.classList.add('header-normal');

        // Cleanup function to remove the class when component unmounts
        return () => {
            document.body.classList.remove('header-normal');
        };
    }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount
    return (
        <div>
            <Helmet>
                <title>
                    Chennai Super Kings
                </title>
                <meta
                    name="description"
                    content="Madarth is a branding, advertising, design and digital marketing agency. We create compelling and relevant solutions for our clients, across verticals."
                />
                <link rel="canonical" href="https://www.madarth.com/work/chennai-super-kings/" />
            </Helmet>
            <section className="project__detail">
                <div className="grid__box">

                    <div className="section__title--content">
                        <div className="text">
                            <h1 className="project__detail--title">Chennai Super Kings</h1>
                            <p className="project__detail--description">Converting physical wins to digital victories for the Champions!</p>
                            <p className="project__detail--tag">
                                <span>Omni channel Strategy</span> |
                                <span>Sports Marketing</span>
                            </p>
                        </div>
                    </div>
                    <div className="section__single">
                        <img src="https://ik.imagekit.io/pixelboy/csk/csk-project-cover.jpg?updatedAt=1722591695644" alt="" />
                    </div>
                    <div className="section__content">
                        <div className="title">
                            <p className="project__detail--type">Omnichannel Strategy</p>
                        </div>
                        <div className="text">
                            <p>Chennai Super Kings is a champion cricket club that takes part in the Indian Premier League. Owned by India Cements and led by the legendary captain MS Dhoni, the team had won four championships at the IPL and had amassed a fandom throughout the globe.</p>
                            <p>As its omnichannel partner, Madarth was given the task of creating a buzz for its game season on the social and physical realms. Chennai Super Kings, a brand known for extending its social media design limitations every year, require us to come up with something unique that no other team could and set a global benchmark in doing so.</p>
                        </div>
                    </div>
                    <div className="section__single">
                        <video src={cskVideo1}
                            poster={cskVideoCover1} autoPlay loop muted preload="auto"
                            width="640" height="360" playsInline className="width:100 height:auto">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={csk2} alt="" />
                    </div>
                    <div className="section__content">
                        <div className="title"><p className="project__detail--type">All Roads lead to Anbuden</p></div>
                        <div className="text">
                            <p>With the vision of cricket returning to Chennai in the summer of 2023, MADARTH decided to capitalise on the homecoming emotion for the season and positioned the auction as the lead up to it. Thus, we landed on the campaign “ALL ROADS LEAD TO ANBUDEN” where ANBUDEN is the brand’s digital IP that denotes the den of the Super Kings.</p>
                            <p>We made player announcement creatives in a stamp theme carrying their headshots and an interesting welcome message.</p>
                            <p>Further, we identified a creative opportunity using reels for the announcements. We also commissioned an animator who brought our creative idea alive, set in the Chennai city scape, welcoming the</p>
                        </div>
                    </div>
                    <div className="section__single padding:bottom:0px">
                        <img loading="lazy" src={csk3} alt="" />
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={csk4} alt="" />
                    </div>
                    <div className="section__single padding:bottom:0px">
                        <img loading="lazy" src={csk5} alt="" />
                    </div>
                    <div className="section__single padding:bottom:0px">
                        <video src={cskVideo2}
                            poster={cskVideoCover2} autoPlay loop muted preload="auto"
                            width="640" height="360" playsInline className="width:100 height:auto">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={csk7} alt="" />
                    </div>
                    <div className="section__content">
                        <div className="title"><p className="project__detail--type">Capturing the electrifying aura</p></div>
                        <div className="text">
                            <p>With cricket coming back to Chennai after long, we decided to focus on capturing the electrifying aura of the stadium during home games. That lead us to the Thunder Theme for the year which flowed as consistent visual language for the season in the digital, social and spatial branding communication at the stadium, player hotels and the player buses.</p>
                            <p>To expand the fervour, fans from Kanyakumari, Tirunelveli, Dindigul, Madurai, Trichy were brought to Chennai in the Whistle Podu Express, a specially chartered train.</p>
                            <p>New heroes emerged in the games and were duly celebrated with special player posters that expanded their persona leading to huge following increase with Chennai Super Kings eventually ending up as the season champions.</p>
                        </div>
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={csk8} alt="" />
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={csk9} alt="" />
                    </div>
                    <div className="section__content">
                        <div className="title"><p className="project__detail--type">Whistle For Blue </p></div>
                        <div className="text">
                            <p>With ICC adopting the strategy of tapping into the emotions of the sport with their Cricket World Cup 2023 campaign called “NAVARASA”, Madarth helped Chennai Super Kings in developing the strategy that would reflect the fan feeling of experiencing the world cup. Hence Madarth devised a two pronged approach to capture the nationwide euphoria:</p>
                            <ul>
                                <li>Following the Indian Cricket Team’s games.</li>
                                <li>Following the games that happen in Chepauk, Chennai.</li>
                            </ul>
                            <p>For the various games India played, the Chennai Super Kings decided to adopt the look and feel of various eras of the world cup showcased in the broadcast right from 1983 to 2023. This gave a sense of nostalgia and familiarity to the fans and reminded them of the glorious journey India has had over the years in the history of the tournament.</p>
                        </div>
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={csk10} alt="" />
                    </div>
                    <div className="section__content">
                        <div className="title"><p className="project__detail--type">Whistle Parakkattum</p></div>
                        <div className="text">
                            <p>As a pre buzz to the season of 2024, The Whistle Parakkatum event was held, bringing together Chennai Super Kings fans to celebrate their community. Superfans met a few star players, shared experiences, and enjoyed fun activities. The event on February 8th in Chennai also reveal our principal sponsor Etihad Airways as a surprise Jersey Launch for the fans.</p>
                        </div>
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={csk11} alt="" />
                    </div>
                    <div className="section__single padding:bottom:0px">
                        <img loading="lazy" src={csk12} alt="" />
                    </div>
                    <div className="section__single padding:bottom:0px">
                        <img loading="lazy" src={csk13} alt="" />
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={csk14} alt="" />
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={csk15} alt="" />
                    </div>
                    <div className="section__single padding:bottom:0px">
                        <img loading="lazy" src={csk16} alt="" />
                    </div>
                    <div className="section__single padding:bottom:0px">
                        <img loading="lazy" src={csk17} alt="" />
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={csk18} alt="" />
                    </div>
                    <div className="section__single">
                        <video src={cskVideo3}
                            poster={cskVideoCover3} autoPlay loop muted preload="auto"
                            width="640" height="360" playsInline className="width:100 height:auto">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="section__single">
                        <video src={cskVideo4}
                            poster={cskVideoCover4} autoPlay loop muted preload="auto"
                            width="640" height="360" playsInline className="width:100 height:auto">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={csk21} alt="" />
                    </div>
                </div>
            </section>
            <FooterCommonCTA
                content="Tell us your story. We’d love to hear from you."
                linkText="Let’s talk"
                linkUrl="/contact-us"
            />
        </div>
    );
}

export default ChenniSuperKings;

import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import ContactForm from './ContactForm';  // Adjust the path as necessary

import contactHero from "../images/madarth-contact-us-hero.png";

import "./contact.scss";

function Contact() {
  return (
    <div>
      <Helmet>
        <title>
          Contact | Madarth | Branding, Advertising, Design &amp; Digital
          Marketing Agency India
        </title>
        <meta
          name="description"
          content="Madarth is a branding, advertising, design, and digital marketing agency. We create compelling and relevant solutions for our clients, across verticals."
        />
        <link rel="canonical" href="https://www.madarth.com/contact-us/" />
      </Helmet>
        <section className="grid__box page--contact__content">
          <div className="page--contact__content--left">
            <div className="page--contact__content--left--title">
            <h1>All it takes is a simple conversation to start our journey.</h1>
            <p>
            We’re from Mylapore. Google up. You’re sure to find a gazillion interesting stuff on Mylapore. Us included.
            </p>
            </div>
            <div className="page--contact__content--left--address">
            <div className="page--contact__content--left--addressWrapper">
            <div className="businessEnquiries">
            <p className="small__title">New business enquiry</p>
            <nav>
              <Link to="mailto:pranitha@madarth.com">
                <strong> Email : </strong>pranitha@madarth.com
              </Link>
              <Link to="tel:+916374115422"> <strong>Call Us :</strong> +91 63741 15422</Link>
            </nav>
            </div>
            <div className="joinTeam">
            <p className="small__title">Join our team</p>
            <ul>
            <li>
              Navigate to our
                <Link to="https://joinatmadarth.notion.site/">careers page</Link>
            </li>
            <li>
              Interns please email your folio to
              <Link to="mailto:narendran@madarth.com">
                narendran@madarth.com
              </Link>
            </li>
            </ul>
            </div>
          </div>
          </div>
          </div>
          <div className="page--contact__content--right">
          <ContactForm />
          </div>
        </section>
        <section className="page--contactHero__banner">
          <img src={contactHero} alt="" />
        </section>
        <section className="iframe__fluid">
          <iframe
            title="Madarth Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3897.1700829676324!2d80.26256257508918!3d13.032635887288377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267924ab51fbb%3A0xe6095c31e2a63041!2sMadarth!5e1!3m2!1sen!2sin!4v1727285507828!5m2!1sen!2sin"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </section>
    </div>
  );
}

export default Contact;

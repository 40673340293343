import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";

import FooterCommonCTA from '../../components/FooterCommonCTA';


import nttfPageCover from "../../images/work/nttf/image/nttf-inner-cover.gif";
import nttf2 from "../../images/work/nttf/image/nttf-2.png";
import nttf3 from "../../images/work/nttf/image/nttf-3.png";
// import nttf4 from "../../images/work/nttf/image/nttf-4.png";

import nttfVideoCover1 from "../../images/work/nttf/video-cover/nttf-1.png";
import nttfVideoCover2 from "../../images/work/nttf/video-cover/nttf-2.png";


import nttfVideo1 from "../../images/work/nttf/video/nttf-1.mp4";
import nttfVideo2 from "../../images/work/nttf/video/nttf-2.mp4";

function Nttf() {
    useEffect(() => {
        // Add the custom class to the body
        document.body.classList.add('header-normal');

        // Cleanup function to remove the class when component unmounts
        return () => {
            document.body.classList.remove('header-normal');
        };
    }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount
    return (
        <div>
            <Helmet>
                <title>
                    NTTF
                </title>
                <meta
                    name="description"
                    content="Madarth is a branding, advertising, design and digital marketing agency. We create compelling and relevant solutions for our clients, across verticals."
                />
                <link rel="canonical" href="https://www.madarth.com/work/nttf/" />
            </Helmet>
            <section className="project__detail">
                <div className="grid__box">
                    <div className="section__title--content">
                        <div className="text">
                            <h1 className="project__detail--title">NTTF</h1>
                            <p className="project__detail--description">Capturing Stories of Impact Through a Coffee Table Book and Corporate Film</p>
                            <p className="project__detail--tag">
                                <span>Branding</span>
                            </p>
                        </div>
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={nttfPageCover} alt="" />
                    </div>
                    {/* <div className="section__title--content">
                    <div className="title">
                        <h2 className="project__detail--year">2023</h2>
                    </div>
                    <div className="text">
                        <h1 className="project__detail--title">NTTF</h1>
                        <p className="project__detail--description">Capturing Stories of Impact Through a Coffee Table Book and Corporate Film
                        </p>
                    </div>
                </div> */}
                    <div className="section__content">
                        <div className="title">
                            <p className="project__detail--type">Rekindling Memorable Memoirs Page After Page</p>
                        </div>
                        <div className="text">
                            <p>For over 60 years, Nettur Technical Training Foundation (NTTF) has been a cornerstone of technical education in India, transforming lives through skill development.</p>
                            <p>To capture the essence of this institution, we embarked on creating a coffee table book titled "An Institution. An Emotion," featuring heartfelt narratives from students, parents, and alumni, showcasing NTTF’s extensive reach and transformative influence. The book is a rich tapestry of personal stories and achievements, reflecting the institution’s legacy in shaping futures.</p>
                            <p>As NTTF’s first coffee table book, we aimed to build a compelling and rich narrative. We began by reaching out to NTTF alumni spread across the country. Conducting interviews, we collected narratives that painted a vivid picture of how NTTF shaped their careers and lives. Each story was a testament to the institution’s impact, reflecting its far-reaching influence and the bonds it forged over the decades.</p>
                            <p>With a treasure trove of stories and testimonials, we meticulously crafted the coffee table book. The design aimed to be as rich and varied as the experiences it contained. Each page was a blend of heartfelt stories and visuals, bringing to life the spirit of NTTF.</p>
                        </div>
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={nttf2} alt="" />
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={nttf3} alt="" />
                    </div>
                    <div className="section__content">
                        <div className="title"><p className="project__detail--type">Corporate Film: NTTF - Shaping Futures</p></div>
                        <div className="text">
                            <p>We produced a corporate film to highlight NTTF’s pivotal role in preparing students for successful careers, featuring testimonials from current students and alumni who have become entrepreneurs and industry leaders. The film encompasses personal stories and views of current students and alumni. Each testimonial weaves a narrative of growth, opportunity, and transformation, emphasising how NTTF’s rigorous training programs equipped them with the skills needed to excel. The film captures the essence of NTTF’s educational philosophy and its commitment to student success.</p>
                            <p>We traveled across various locations to film the testimonials, capturing the alumni and current students in their professional environments. The editing process focused on creating a seamless narrative that highlighted the core message: NTTF doesn’t just educate; it transforms lives. The final product is a compelling visual story that underscores the impact of NTTF’s training on individual careers and the broader community.</p>
                            <p>In creating both the coffee table book and the corporate film, our aim was to capture and celebrate the profound impact of NTTF on its students and the community. Through rich narratives and powerful visuals, we showcased how NTTF has not only provided technical education but has also been a transformative force in the lives of countless individuals. This project stands as a tribute to NTTF’s enduring legacy and its unwavering commitment to shaping futures.</p>
                        </div>
                    </div>
                    <div className="section__single">
                        <video src={nttfVideo1} poster={nttfVideoCover1} autoPlay loop
                            muted preload="auto" width="640" height="360" playsInline className="width:100 height:auto">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="section__single">
                        <video src={nttfVideo2} poster={nttfVideoCover2} autoPlay loop
                            muted preload="auto" width="640" height="360" playsInline className="width:100 height:auto">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </section>
            <FooterCommonCTA
                content="Tell us your story. We’d love to hear from you."
                linkText="Let’s talk"
                linkUrl="/contact-us"
            />
        </div>
    );
}

export default Nttf;

import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";

import FooterCommonCTA from '../../components/FooterCommonCTA';

import sundarisilksUtsvaCover from "../../images/work/sundarisilks-utsva/image/sundari-utsva-cover.png";
import sundarisilksUtsva1 from "../../images/work/sundarisilks-utsva/image/sundari-utsva-1.png";
import sundarisilksUtsva2 from "../../images/work/sundarisilks-utsva/image/sundari-utsva-2.png";
import sundarisilksUtsva3 from "../../images/work/sundarisilks-utsva/image/sundari-utsva-3.png";

import sundarisilksUtsvaVideoCover1 from "../../images/work/sundarisilks-utsva/video-cover/sundari-utsva-1.png";
import sundarisilksUtsvaVideoCover2 from "../../images/work/sundarisilks-utsva/video-cover/sundari-utsva-2.png";
import sundarisilksUtsvaVideoCover3 from "../../images/work/sundarisilks-utsva/video-cover/sundari-utsva-3.png";

import sundarisilksUtsvaVideo1 from "../../images/work/sundarisilks-utsva/video/sundari-utsva-1.mp4";
import sundarisilksUtsvaVideo2 from "../../images/work/sundarisilks-utsva/video/sundari-utsva-2.mp4";
import sundarisilksUtsvaVideo3 from "../../images/work/sundarisilks-utsva/video/sundari-utsva-3.mp4";

function SundariSilksMargazhiUtsavam() {
    useEffect(() => {
        // Add the custom class to the body
        document.body.classList.add('header-normal');

        // Cleanup function to remove the class when component unmounts
        return () => {
            document.body.classList.remove('header-normal');
        };
    }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount
    return (
        <div>
            <Helmet>
                <title>
                    Sundari Silks Margazhi Utsavam
                </title>
                <meta
                    name="description"
                    content="Madarth is a branding, advertising, design and digital marketing agency. We create compelling and relevant solutions for our clients, across verticals."
                />
                <link rel="canonical" href="https://www.madarth.com/work/sundari-silks-margazhi-utsavam/" />
            </Helmet>
            <section className="project__detail">
                <div className="grid__box">
                    <div className="section__title--content">
                        <div className="text">
                            <h1 className="project__detail--title">Sundari Silks</h1>
                            <p className="project__detail--description">Sundari Silks Presents Margazhi Utsavam</p>
                            <p className="project__detail--tag">
                                <span>Omni channel Strategy</span> |
                                <span>Website</span>
                            </p>
                        </div>
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={sundarisilksUtsvaCover} alt="" />
                    </div>
                    {/* <div className="section__title--content">
                    <div className="title">
                        <h2 className="project__detail--year">2023</h2>
                    </div>
                    <div className="text">
                        <h1 className="project__detail--title">Sundari Silks</h1>
                        <p className="project__detail--description">Sundari Silks Presents Margazhi Utsavam
                        </p>
                    </div>
                </div> */}
                    <div className="section__content">
                        <div className="title">
                            <p className="project__detail--type">Omnichannel campaign</p>
                        </div>
                        <div className="text">
                            <p>Celebrated in the Tamil month of Margazhi, Margazhi Utsavam is a vibrant cultural extravaganza in Tamil Nadu. Renowned for daily performances of classical Carnatic music and Bharatanatyam dance by acclaimed artists, the festival embodies the rich heritage of Tamil Nadu. Devotees wake up early to sing hymns and bhajans, adding a spiritual dimension to the celebration. Temples host special rituals and cultural events, enhancing the festive atmosphere. Margazhi Utsavam attracts enthusiasts and scholars from around the world, promoting cultural exchange and appreciation.</p>
                            <p>Sundari silks took a bold move. They wanted to create their own Utsavam. It's a strategic way to differentiate themselves in the crowded sponsorship landscape and establish a unique cultural footprint. This initiative could also serve to strengthen community ties and foster brand loyalty among attendees who appreciate Sundari Silks' commitment to cultural enrichment. We partnered with Sundari Silks to bring their vision of Margazhi Utsavam to life. We collaborated closely with Sundari Silks to develop an event concept that resonates with their brand ethos and the cultural significance of Margazhi Utsavam.</p>
                        </div>
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={sundarisilksUtsva1} alt="" />
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={sundarisilksUtsva2} alt="" />
                    </div>
                    <div className="section__single">
                        <img loading="lazy" src={sundarisilksUtsva3} alt="" />
                    </div>
                    <div className="section__content">
                        <div className="text">
                            <p>Margazhi Utsavam nurtures young talents by offering them a prestigious platform to showcase their skills and has a significant economic impact, boosting tourism and supporting local businesses. We selected renowned artists and promising talents to perform, ensuring a rich and diverse cultural experience.</p>
                        </div>
                    </div>
                    <div className="section__single">
                        <video src={sundarisilksUtsvaVideo1} poster={sundarisilksUtsvaVideoCover1} autoPlay loop
                            muted preload="auto" width="640" height="360" playsInline className="width:100 height:auto">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="section__content">
                        <div className="text">
                            <p>We executed a comprehensive digital marketing campaign to promote the event, leveraging social media, email marketing, and online advertising to reach a wide audience.</p>
                        </div>
                    </div>
                    <div className="section__single">
                        <video src={sundarisilksUtsvaVideo2} poster={sundarisilksUtsvaVideoCover2} autoPlay loop
                            muted preload="auto" width="640" height="360" playsInline className="width:100 height:auto">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="section__single">
                        <video src={sundarisilksUtsvaVideo3} poster={sundarisilksUtsvaVideoCover3} autoPlay loop
                            muted preload="auto" width="640" height="360" playsInline className="width:100 height:auto">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="section__content">
                        <div className="text">
                            <p>Every icon in the Indian music scene, wearing Sundari Silks sarees and singing a rendition of Sundari’s audio mark, made this Utsavam the event of the year.</p>
                            <p>This work not only brought the essence of Margazhi Utsavam to life but also reinforced Sundari Silks' commitment to cultural celebration and community engagement.</p>
                        </div>
                    </div>
                </div>
            </section>
            <FooterCommonCTA
                content="Tell us your story. We’d love to hear from you."
                linkText="Let’s talk"
                linkUrl="/contact-us"
            />
        </div>
    );
}

export default SundariSilksMargazhiUtsavam;

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import holidaysData from "./holidays.json"; // Import JSON data
import './CalendarComponent.scss'; // For custom styling

const CalendarComponent = () => {
  const [holidays, setHolidays] = useState([]);

  useEffect(() => {
    // Load holidays from the imported JSON data
    setHolidays(holidaysData);
  }, []);

  // Function to check if a date is a holiday (showing one day before)
  const isHoliday = (date) => {
    const dateString = moment
      .utc(date)
      .subtract(-1, "days")
      .format("YYYY-MM-DD"); // Subtract 1 day
    return holidays.some((holiday) => holiday.date === dateString);
  };

  // Function to get the holiday name for a given date (showing one day before)
  const getHolidayName = (date) => {
    const dateString = moment
      .utc(date)
      .subtract(-1, "days")
      .format("YYYY-MM-DD"); // Subtract 1 day
    const holiday = holidays.find((holiday) => holiday.date === dateString);
    return holiday ? holiday.name : null;
  };

  return (
    <div>
      <Helmet>
        <title>
          Calendar | Madarth | Branding, Advertising, Design &amp; Digital
          Marketing Agency India
        </title>
        <meta
          name="description"
          content="Madarth is a branding, advertising, design, and digital marketing agency. We create compelling and relevant solutions for our clients, across verticals."
        />
        <link rel="canonical" href="https://www.madarth.com/calendar/" />
      </Helmet>
      <div className="page--calendar">
        <div className="calendar-header">
          <div className="calendar-name">
            <span className="calendar-icon"></span>
            <span className="calendar-title">Holiday</span>
          </div>
        </div>
        <Calendar
          tileClassName={({ date, view }) => {
            if (view === "month" && isHoliday(date)) {
              return "holiday"; // Add a 'holiday' class to the tile
            }
            return null;
          }}
          tileContent={({ date, view }) => {
            if (view === "month" && isHoliday(date)) {
              return (
                <span className="holiday-label">{getHolidayName(date)}</span>
              );
            }
            return null;
          }}
        />
      </div>
    </div>
  );
};

export default CalendarComponent;
